import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

export default class Navbar extends Component {
  static propTypes = {
    brand: PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
      })
    ),
  }

  constructor(props) {
    super(props)
    this.state = {
      show: false,
      scrollPos: 0,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    const { scrollPos } = this.state
    this.setState({
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top > scrollPos,
    })
  }

  render() {
    const NavLinks = () =>
      this.props.links.map((link, index) => (
        <StyledNavLink key={index} to={link.to}>
          {link.name}
        </StyledNavLink>
      ))

    return (
      <Transition>
        <StyledNavbar className={this.state.show ? "active" : "hidden"}>
          <Logo>
            <Link to="/">LatestBlitz</Link>
          </Logo>
          <nav>
            <NavLinks />
          </nav>
        </StyledNavbar>
      </Transition>
    )
  }
}
const Transition = styled.div`
  .active {
    visibility: visible;
    transition: all 300ms ease-in;
  }
  .hidden {
    visibility: hidden;
    transition: all 300ms ease-out;
    transform: translate(0, -100%);
  }
`

const StyledNavbar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bolder;
  z-index: 1000;
  background-color: #3ec7c2;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 3px 5px 11px 2px hsla(0, 0%, 0%, 0.26);

  a {
    margin-right: 1rem;
    font-weight: normal;
    color: #000000;
    :hover {
    }
  }
  .brand {
    font-style: italic;
    margin-left: 1rem;
    font-weight: bold;
    color: #000000;
    font-size: 1.25rem;
  }
`

const StyledNavLink = styled(Link)`
  margin-right: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;

  display: inline-block;
  padding: 15px 20px;
  position: relative;
  :after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  :hover:after {
    width: 100%;
    left: 0;
  }
`

const Logo = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 0;
  max-width: 10vw;
  text-align: center;
  padding: 0;
  img {
    margin-bottom: 0;
    padding: 0;
  }
  a {
    margin: 0;
  }
  @media (max-width: 849px) {
    width: 100%;
    max-width: 125px;
    display: block;
    margin: 1px auto 0;
    img {
      margin-bottom: 0;
    }
    p {
      text-align: center;
    }
  }
`
