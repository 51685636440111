/** @jsx jsx */
import { jsx, ThemeProvider, Layout, Styled } from "theme-ui"
import { Link } from "gatsby"

import { Global, css } from "@emotion/core"

import theme from "../gatsby-plugin-theme-ui"
import Navbar from "./navbar"
import "../prism-styles"

// Create styles for the Global component
const globalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    padding: 0;
  }
`
const navlinks = [
  { name: "Home", to: "/" },
  { name: "Blog", to: "/blog-index" },
]
const brand = { name: "Company", to: "/" }

const Container = props => (
  <div
    {...props}
    sx={{
      marginTop: "layout.container.compact",
      maxWidth: "layout.sizes.container",
      mx: "auto",
      px: 0,
      // marginTop: 600,
    }}
  />
)
export default props => (
  <ThemeProvider theme={theme}>
    <Global styles={globalStyles} />
    <Styled.root>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          variant: "styles",
        }}
      >
        <header
          sx={{
            variant: "layout.header",
          }}
        >
          <div
            sx={{
              mx: "auto",
              px: 3,
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Navbar brand={brand} links={navlinks} />
          </div>
        </header>
        <main
          sx={{
            width: "100%",
            flex: "1 1 auto",
            variant: "layout.main",
          }}
          id="main-area"
        >
          <Container>{props.children}</Container>
        </main>
        <footer
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            p: 2,
            variant: "layout.footer",
          }}
        >
          <div sx={{ display: "none" }}>
            <Link to="/">Home</Link>
          </div>

          <div sx={{ mx: "auto" }} />
          <div sx={{ p: 2 }}>
            © {new Date().getFullYear()}
            {` `} <Link to="/">LatestBlitz.com</Link>
          </div>
        </footer>
      </div>
    </Styled.root>
  </ThemeProvider>
)
